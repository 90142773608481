import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: '100%',
        gap: 20,
        padding: '0px 30px',
        borderBottom: '1px solid #C6C5F4'
    },
    imageContainer: {
        paddingLeft: 30,
    },
    logo: {
        width: 204,
    },
    navBarContainer: {
        display: "flex",
        minHeight: 56,
    },
    link: {
        fontSize: '14px',
        fontWeight: '400',
        textDecoration: 'none',
        lineHeight: '3.5',
        color: '#242375',
        padding: "0 10px 0 10px",
        height: "100%",
    },
}, {
    name: 'header',
});

export default useStyles;
