import useStyles from "./styles";
import React, {useCallback, useState} from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {ICardGroup} from "./types";
import {ICard} from "../../globalTypes/globalTypes";
import InfoCard from "../infoCard/InfoCard";
import {getContexBy} from "../../Services/HttpRequest";
import clsx from 'clsx';
import IconOpen from "../../assets/icons/IconOpen";
import IconClose from "../../assets/icons/IconClose";
import {cards} from "../../Utility/mocs";

const CardGroup = ({
                      context,
    cardID,
    contextType,
                  }: ICardGroup) => {
    const styles = useStyles();
    const [showCards, setShowCards] = useState<boolean>(false);
    const [lawCards, setLawCards] = useState<ICard[]>([]);

    const onGroupCardClick = useCallback(() => {
        if (!showCards && lawCards.length === 0) {
            const getCards = async () => {
                try {
                    const fetchedCard = await getContexBy(cardID, context.contextId, contextType);
                    fetchedCard && setLawCards(fetchedCard as ICard[])
                } catch (error) {
                    console.log("error", error);
                }
            };
            getCards();
        }
        setShowCards(!showCards);
    }, [cardID, context.contextId, contextType, lawCards.length, showCards]);

    return (
        <div className={styles.root}>
            <Card
                onClick={onGroupCardClick}
                className={clsx(styles.groupCard, { [styles.activeGroupCard]: showCards })}
            >
                <CardContent className={styles.groupCardContent}>
                    <Typography variant="body2" style={{ fontWeight: 400, fontSize: "14px", lineHeight: "19.07px", userSelect: "none" }}>
                        {context.contextName}
                    </Typography>
                    {showCards ? <IconOpen className={styles.iconClose} /> : <IconClose className={styles.iconClose} />}
                </CardContent>
            </Card>
            {showCards && cards.map((card: ICard) => (
                <div style={{padding: "5px 0"}}>
                    {lawCards && lawCards.map(card => <InfoCard card={card} needShadow={false}/>)}
                </div>
            ))}
        </div>
    );
}

export default CardGroup;
