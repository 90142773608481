import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        cursor: 'pointer',
        backgroundColor: "#FCFCFC",
        borderRadius: 10,
        boxShadow:  ({ needShadow }: { needShadow: boolean }) => !needShadow && 'none',
    },
}, {
    name: 'info-card',
});

export default useStyles;
