import React, { createContext, useContext, useRef } from 'react';

interface HistoryContextType {
    historyStack: string[];
    futureStack: string[];
    addHistory: (path: string) => void;
    goBack: () => string | undefined;
    goForward: () => string | undefined;
    canGoBack: () => boolean;
    canGoForward: () => boolean;
}

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const historyStackRef = useRef<string[]>([]);
    const futureStackRef = useRef<string[]>([]);

    const addHistory = (path: string) => {
        if (historyStackRef.current.length === 0 || historyStackRef.current[historyStackRef.current.length - 1] !== path) {
            historyStackRef.current.push(path);
            futureStackRef.current = [];
        }
    };

    const goBack = () => {
        if (historyStackRef.current.length > 1) {
            const previousPath = historyStackRef.current[historyStackRef.current.length - 2];
            futureStackRef.current.unshift(historyStackRef.current.pop()!); // Move current path to future stack
            return previousPath;
        } else if (historyStackRef.current.length === 1) {
            historyStackRef.current = [];
        }
        return undefined;
    };

    const goForward = () => {
        if (futureStackRef.current.length > 0) {
            const nextPath = futureStackRef.current.shift()!;
            historyStackRef.current.push(nextPath);
            return nextPath;
        }
        return undefined;
    };

    const canGoBack = () => historyStackRef.current.length > 1;
    const canGoForward = () => futureStackRef.current.length > 0;

    return (
        <HistoryContext.Provider value={{ historyStack: historyStackRef.current, futureStack: futureStackRef.current, addHistory, goBack, goForward, canGoBack, canGoForward }}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistory = () => {
    const context = useContext(HistoryContext);
    if (!context) {
        throw new Error('useHistory must be used within a HistoryProvider');
    }
    return context;
};
