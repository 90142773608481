import {createUseStyles} from "react-jss";
import {colors} from "../../assets/colors";

const useStyles = createUseStyles({
    root: { },
    groupCard: {
        cursor: 'pointer',
        color: colors.groupCard.default.color,
        backgroundColor: colors.groupCard.default.backgroundColor,
        borderRadius: 10,
        transition: 'background-color 0.1s linear, transform 0.3s ease',
        '&:hover': {
            color: colors.groupCard.hover.color,
            backgroundColor: colors.groupCard.hover.backgroundColor,
        },
    },
    activeGroupCard: {
        color: colors.groupCard.active.color,
        backgroundColor: colors.groupCard.active.backgroundColor,
        transform: 'scale(1.02)',
    },
    groupCardContent: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    iconClose: {
        width: 40,
        height: 24,
    },
}, {
    name: 'news-card',
});

export default useStyles;
