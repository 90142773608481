import useStyles from "./styles";
import React from "react";
import {Button, InputBase} from "@mui/material";
import IconSearch from "../../assets/icons/iconSearch.svg";
import {ISearchBar} from "./types";
import {colors} from "../../assets/colors";

const SearchBar = ({
                       title,
                       onSerach,
                       placeholder = "որոնվող տեքստ, օրենք, հոդված, տարեթիվ",
                       inputValue = "",
                       setInputValue,
}: ISearchBar) => {
    const styles = useStyles()
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSerach(inputValue)
        }
    };

    return (
        <div className={styles.root}>
            {<img src={IconSearch} alt="Send Icon" style={{padding: 10, width: 24, height: 24}}/>}
            <InputBase
                value={inputValue}
                sx={{ml: 1, flex: 1,}}
                placeholder={placeholder}
                onChange={event => setInputValue?.(event.target.value)}
                onKeyDown={handleKeyDown}
            />
            <Button
                variant={"contained"}
                sx={{
                    width: 106,
                    minHeight: 56,
                    backgroundColor: colors.serachBar.button.default.backgroundColor,
                    color: colors.serachBar.button.default.color,
                    borderLeft: `1px solid ${colors.serachBar.button.border}`,
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: colors.serachBar.button.hover.backgroundColor,
                        color: colors.serachBar.button.hover.color,
                    },
                    "&:active": {
                        backgroundColor: colors.serachBar.button.active.backgroundColor,
                        color: colors.serachBar.button.active.color,
                    },
                    fontSize: 12,
                    fontWeight: 500,
                }}
                onClick={() => onSerach(inputValue)}
            >
                {title}
            </Button>
        </div>
    );
}

export default SearchBar;
