export enum ContextTypeEnum {
    inGoing= "INCOMING",
    outGoing = "OUTGOING"
}

export interface IContext {
    contextId: string;
    contextName: string;
    count: number;
}

export interface ICard {
    id: string;
    title: string;
    description?: string;
    content: string;
    isValid?: boolean
    path?: string;
    incoming?: IContext[];
    outgoing?: IContext[];
}
