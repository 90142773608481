import axios from "axios";
import {ContextTypeEnum, ICard} from "../globalTypes/globalTypes";
import {IGraphData} from "../pages/mainPages/home/types";

const apiUrl = process.env.REACT_APP_API_URL;
const chatApiUrl = process.env.REACT_APP_CHAT_API_URL;
export const getCardBy = async (id: string) => {

    try {
        const response = await axios({
            method: 'get',
            url: `${apiUrl}/units/item`,
            params: {
                id: id,
            },
        });
        return (response.data as ICard);
    } catch (error) {
        return ({
            response: null,
            error: error,
        });
    }
}

export const getCardsBy = async (tag: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${apiUrl}/knodes/_search`,
            params: {
                query: tag,
            },
        });
        return (response.data as ICard[]);
    } catch (error) {
        throw error;
    }
}

export const getContexBy = async (cardId: string, contextId: string, contextType: ContextTypeEnum) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${apiUrl}/units/relationships`,
            params: {
                id: cardId,
                contextId: contextId,
                type: contextType,
            },
        });
        return (response.data as ICard[]);
    } catch (error) {
        throw error;
    }
}

export const sendChatText = async (text: string) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${chatApiUrl}/questions/ask`,
            data: {
                text: text,
            },
        });
        return (response.data as {text: string});
    } catch (error) {
        throw error;
    }
}

export const getGraphData = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: `${apiUrl}/graph`,
            params: {
                skip: 0,
                limit: 2000,
            },
        });
        return (response.data as IGraphData);
    } catch (error) {
        throw error;
    }
}
