import {IContentLayout} from "./types";
import useStyles from "./styles";

const ContnetLayout = ({
                           left,
                           center,
                           right,
                           isFocusInContent,
                       }: IContentLayout) => {
    const styles = useStyles({isFocusInContent} as any);

    return (
        <div className={styles.root}>
            <div className={styles.leftContainer}>
                {left}
            </div>
            <div className={styles.centerContainer}>
                {center}
            </div>
            <div className={styles.rightContainer}>
                {right}
            </div>
        </div>
    )
}

export default ContnetLayout;
