import {ICard} from "../globalTypes/globalTypes";
import {IGraphData} from "../pages/mainPages/home/types";

export const content = `Հոդված 1.\\tՔաղաքացիական օրենսդրությամբ և քաղաքացիական իրավունքի նորմեր պարունակող այլ իրավական ակտերով կարգավորվող հարաբերությունները\\n' +
          ' \\n' +
          '\\n' +
          '1. Հայաստանի Հանրապետության քաղաքացիական օրենսդրությունը կազմված է սույն օրենսգրքից և քաղաքացիական իրավունքի նորմեր պարունակող այլ օրենքներից:\\n' +
          '\\n' +
          'Այլ օրենքներում պարունակվող քաղաքացիական իրավունքի նորմերը պետք է համապատասխանեն սույն օրենսգրքին:\\n' +
          '\\n' +
          '2. Քաղաքացիական օրենսդրությունը, ինչպես նաև քաղաքացիական իրավունքի նորմեր պարունակող\` Հայաստանի Հանրապետության Նախագահի հրամանագրերը և Հայաստանի Հանրապետության կառավարության որոշումները (այսուհետ\` այլ իրավական ակտեր) սահմանում են քաղաքացիական շրջանառության մասնակիցների իրավական վիճակը, սեփականության իրավունքի և այլ գույքային իրավունքների, մտավոր գործունեության արդյունքների նկատմամբ բացառիկ իրավունքների (մտավոր սեփականություն) ծագման հիմքերն ու իրականացման կարգը, կարգավորում են պայմանագրային և այլ պարտավորություններ, ինչպես նաև այլ գույքային ու դրանց հետ կապված անձնական ոչ գույքային հարաբերություններ:\\n' +
          '\\n' +
          'Քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով կարգավորվող հարաբերությունների մասնակիցները\` ֆիզիկական անձինք\` Հայաստանի Հանրապետության քաղաքացիները, օտարերկրյա պետությունների քաղաքացիները, քաղաքացիություն չունեցող անձինք (այսուհետ\` քաղաքացիներ) ու իրավաբանական անձինք են, ինչպես նաև Հայաստանի Հանրապետությունը և համայնքներն են (հոդված 128):\\n' +
          '\\n' +
          'Քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով սահմանված կանոնները կիրառվում են օտարերկրյա իրավաբանական անձանց մասնակցությամբ հարաբերությունների նկատմամբ, եթե այլ բան նախատեսված չէ օրենքով:\\n' +
          '\\n' +
          '3. Քաղաքացիական օրենսդրությունը և այլ իրավական ակտերը կարգավորում են ձեռնարկատիրական գործունեություն իրականացնող անձանց միջև կամ նրանց մասնակցությամբ հարաբերությունները:\\n' +
          '\\n' +
          '4. Ընտանեկան, աշխատանքային, բնական պաշարների օգտագործման ու շրջակա միջավայրի պահպանության հարաբերությունները կարգավորվում են քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով, եթե այլ բան նախատեսված չէ ընտանեկան, աշխատանքային, հողային, բնապահպան և այլ հատուկ օրենսդրությամբ:\\n' +
          '\\n' +
          '5. Մարդու անօտարելի իրավունքների ու ազատությունների և մյուս ոչ նյութական բարիքների իրականացման ու պաշտպանության հետ կապված հարաբերությունները կարգավորվում են քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով, եթե այլ բան չի բխում այդ հարաբերությունների էությունից:\\n' +
          '\\n' +
          '6. Մեկ կողմի\` մյուսի վարչական կամ այլ իշխանական ենթակայության վրա հիմնված գույքային\` ներառյալ հարկային, ֆինանսական ու վարչական հարաբերությունների նկատմամբ քաղաքացիական օրենսդրությունը և այլ իրավական ակտերը չեն կիրառվում, եթե այլ բան նախատեսված չէ օրենսդրությամբ:`

export const cards: ICard[] = [
    {
        id: "civil_code-article_1",
        title: "ՀԱՅԱՍՏԱՆԻ ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ՔԱՂԱՔԱՑԻԱԿԱՆ ՕՐԵՆՍԳԻՐՔ",
        description: "Օրենսգիրք Ընդունված է։ 06.12.1985  Ուժի մեջ է։  01.06.1986",
        isValid: true,
        content: content,
        incoming: [
            {
                contextId: "1",
                contextName: "Քարտային տվյալներ",
                count: 0,
            },
            {
                contextId: "2",
                contextName: "Նախագծի մասին",
                count: 0,
            },
            {
                contextId: "3",
                contextName: "ժամ.տարբերակներ",
                count: 0,
            },
            {
                contextId: "4",
                contextName: "դատական ակտեր",
                count: 0,
            }],
        outgoing: [
            {
                contextId: "1",
                contextName: "Քարտային տվյալներ",
                count: 0,
            },
            {
                contextId: "2",
                contextName: "Նախագծի մասին",
                count: 0,
            },
            {
                contextId: "3",
                contextName: "ժամ.տարբերակներ",
                count: 0,
            },
            {
                contextId: "4",
                contextName: "դատական ակտեր",
                count: 0,
            }],
    },
];

export const initialGraphData: IGraphData = {
    nodes: [
        { id: '1', title: 'Հոդված 1', context: "qax_or"},
        { id: '2', title: 'Հոդված 2', context: "qax_or"},
        { id: '3', title: 'Հոդված 3', context: "qax_or"},
        { id: '4', title: 'Հոդված 4', context: "vchr_dat_nax"},
        { id: '128', title: 'Հոդված 128', context: "vchr_dat_nax"},
        { id: '129', title: 'Հոդված 128', context: "vchr_dat_nax"},
    ],
    edges: [
        { source: '1', target: '2' },
        { source: '1', target: '3' },
        { source: '2', target: '3' },
        { source: '2', target: '4' },
        { source: '4', target: '128' },
    ]
};
