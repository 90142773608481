import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: { },
    searchContainer: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        width: '100%',
        height: '150px',
        backgroundColor: "#F4F3F3",
        padding: "0 80px",
        color: "#242375",
    },
    divider: {
        backgroundColor: "#D5D5D5",
        width: '100%',
        height: '1px',
    },
    contactContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        padding: "20px 80px",
        color: "#242375",
    }
}, {
    name: 'contact-us',
});

export default useStyles;
