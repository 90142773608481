import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        width: '100vw',
        minHeight: '100vh',
        display: "grid",
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateColumns: '1fr',
    },
    row: {
        minWidth: 0,
        minHeight: 0,
    },
    headerContainer: {
        height: 88,
        backgroundColor: "white",
    },
    contentContainer: {
        padding: "20px, 0",
    },
    footerContainer: {
        height: 42,
        backgroundColor: "#110F7E",
        borderTop: "4px solid #EDCC77"
    },
}, {
    name: 'layout',
});

export default useStyles;
