import React, {useCallback, useEffect, useState} from "react";
import InfoCard from "../../../components/infoCard/InfoCard";
import useStyles from "./styles";
import SearchBar from "../../../components/searchBar/SearchBar";
import {ICard} from "../../../globalTypes/globalTypes";
import {getCardsBy, getGraphData} from "../../../Services/HttpRequest";
import {CircularProgress, Snackbar, Typography} from "@mui/material";
import {cards} from "../../../Utility/mocs";
import Graph from "./Graph";
import {IGraphData} from "./types";
import {useNavigate} from "react-router-dom";
import {style} from "d3";

const Home = () => {
    const styles = useStyles();

    const [searchResultCards, setSearchResultCards] = useState<ICard[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [searchWasClicked, setSearchWasClicked] = useState<boolean>(false)
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [data, setData] = useState<IGraphData>({nodes: [], edges: []});
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = useState<boolean>(false);

    useEffect(() => {
        const savedInput = sessionStorage.getItem("searchInput");
        const savedResults = sessionStorage.getItem("searchResults");

        if (savedInput) {
            setInputValue(savedInput);
        }
        if (savedResults) {
            setSearchResultCards(JSON.parse(savedResults));
        }
        console.log("Effect");
        getData();
    }, []);

    const onSearchButton = useCallback((text: string) => {
        const getCards = async () => {
            try {
                const fetchedCard = await getCardsBy(text);
                setSearchWasClicked(true);
                if (fetchedCard) {
                    setSearchResultCards(fetchedCard as ICard[]);
                    sessionStorage.setItem("searchResults", JSON.stringify(fetchedCard));
                }
            } catch (error) {
                console.log("error", error);
                setShowSnackBar(true)
            }
        };

        // Save search input to sessionStorage
        sessionStorage.setItem("searchInput", text);
        getCards();
    }, []);

    const getData = () => {
        const getGData = async () => {
            try {
                setShowLoading(true);
                const fetchedData = await getGraphData();
                if (fetchedData) {
                    console.log("fetchedData", fetchedData);
                    fetchedData.nodes.push({id: "civil_code", title: "Qax OR"})
                    fetchedData.nodes.push({id: "trial_court_decision", title: "Dec"})
                    setData(fetchedData);
                    setShowLoading(false);
                }
            } catch (error) {
                console.log("error", error);
                setShowSnackBar(true)
            }
        };
        getGData();
    };

    useEffect(() => {
        setSearchWasClicked(false);
    }, [inputValue]);

    const getCards = (result: ICard[]) => {
        if (result.length > 0) {
            return result;
        } else if (inputValue.length > 0 && searchWasClicked) {
            return null;
        }
        return cards;
    };

    const onNode = (id: String) => {
        navigate(`/views?id=${id}`);
    }

    return (
        <div className={styles.root}>
            <div className={styles.searchContainer}>
                <SearchBar
                    title={"Որոնել"}
                    onSerach={onSearchButton}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            </div>
            <div className={styles.newsCardListContainer} >
                {getCards(searchResultCards) ? getCards(searchResultCards)!.map((card: ICard) => (
                    <InfoCard
                        card={card}
                        needShadow
                    />
                ))
                    :
                    <Typography variant={'h6'} textAlign={"center"}>
                        {`"${inputValue}" բառով որոնման արդյունքը 0 է`}
                    </Typography>
                }
            </div>
            <div className={styles.graphContainer}>
                {showLoading ?
                <div className={styles.circleProgress}>
                    <CircularProgress />
                </div>
                    :
                <Graph data={data} onNodeClick={onNode} />
                }
            </div>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center" }}
                open={showSnackBar}
                autoHideDuration={3000}
                onClose={() => setShowSnackBar(false)}
                message="Կապի խափանում, խնդրում ենք նորից փորձեք"
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#706EE3',
                        color: 'white',
                        borderRadius: 2,
                    },
                }}
            />
        </div>
    )
}

export default Home;
