import React from "react";
import useStyles from "./styles";
import {Typography} from "@mui/material";

const ContactUs = () => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <div className={styles.searchContainer}>
                <Typography variant="h4"
                            align='left'
                >
                    Contact us
                </Typography>
            </div>
            <div className={styles.contactContainer}>
                <div>
                    <Typography variant="body1"
                                align='left'
                                fontWeight={600}
                    >
                        "ԻՆՖՈՎԵՐՍ" ՍՊԸ
                    </Typography>

                </div>
                <div>
                    <Typography
                        variant="body2"
                        align='left'
                    >
                        ք.Երևան 0010
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="body2"
                        align='left'
                    >
                        +374 55545514
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="body2"
                        align='left'
                    >
                        +374 94834960
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
