import ChatAI from "./ChatAI";
import useStyles from "./styles";

const ChatAIContainer = () => {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <ChatAI />
        </div>
    )
}
export default ChatAIContainer;
