import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '99%',
        paddingTop: 3,
    },
    questionContainer: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        width: 700,
        gap: 8,
    },

}, {
    name: 'chat-ai',
});

export default useStyles;
