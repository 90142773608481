import useStyles from "./styles";
import { ILayout } from "./types";
import React from "react";
import clsx from "clsx";
import {HistoryProvider} from "../../../providers/HistoryProvider";

const Layout = ({
    header,
    content,
    footer,
 }: ILayout) => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            {header && (
              <div className={clsx(styles.row, styles.headerContainer)}>
                  {header}
              </div>
            )}
            <div className={clsx(styles.row, styles.contentContainer)}>
                {content}
            </div>
            {footer && (
                <div className={clsx(styles.row, styles.footerContainer)}>
                    {footer}
                </div>
            )}
        </div>
    )
};

export default Layout;
