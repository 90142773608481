import useStyles from "./styles";
import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {INewsCard} from "./types";
import {Link} from "react-router-dom";

const InfoCard = ({
                      card,
                      needShadow,
                  }: INewsCard) => {
    const styles = useStyles({needShadow} as any)

    return (
        <Link to={`/views?id=${card.id}`} style={{ textDecoration: 'none' }}>
            <Card className={styles.root}>
                <CardContent>
                    <Typography variant="body2">
                        {card.title}
                    </Typography>
                    <Typography
                        variant="caption"
                    >
                        {card.description}
                        {card.isValid && <span style={{color: "#037408", marginLeft: '8px'}}>Գործում է</span>}
                    </Typography>
                    {/*<Typography*/}
                    {/*    variant="caption"*/}
                    {/*    sx={{*/}
                    {/*        display: '-webkit-box',*/}
                    {/*        WebkitLineClamp: 2,*/}
                    {/*        WebkitBoxOrient: 'vertical',*/}
                    {/*        overflow: 'hidden',*/}
                    {/*        textOverflow: 'ellipsis',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Showdown markdown={card.content ?? ""}/>*/}
                    {/*    {card.isValid && <span style={{color: "#037408", marginLeft: '8px'}}>Գործում է</span>}*/}
                    {/*</Typography>*/}
                </CardContent>
            </Card>
        </Link>
    );
}

export default InfoCard;
