import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "grid",
        gridTemplateRows: '1fr',
        gridTemplateColumns: ({ isFocusInContent }: { isFocusInContent: boolean }) => isFocusInContent ? 'auto minmax(500px, auto) auto' : 'minmax(0, 342px) minmax(500px, auto) minmax(0, 342px)',
        gap: 16,
        height: "100%",
        width: "100%"
    },
    leftContainer: {
        // display: ({ isFocusInContent }: { isFocusInContent: boolean }) => isFocusInContent && 'none',
    },
    centerContainer: {
        maxWidth: "100%",
    },
    rightContainer: {
        // display: ({ isFocusInContent }: { isFocusInContent: boolean }) => isFocusInContent && 'none',
    },
}, {
    name: 'content-layout',
});

export default useStyles;
