import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Layout} from "./components/layout/mainLayout";
import {Header} from "./components/header";
import {BrowserRouter} from "react-router-dom";
import {IPage} from "./components/content/types";
import LegalActs from "./pages/mainPages/legalActs/LegalActs";
import Projects from "./pages/mainPages/projects/Projects";
import ContactUs from "./pages/mainPages/contactUs/ContactUs";
import Content from "./components/content/Content";
import Home from "./pages/mainPages/home/Home";
import {createTheme, ThemeProvider} from "@mui/material";
import Views from "./pages/contentPages/views/Views";
import ChatAIContainer from "./pages/mainPages/chatAI/ChatAIContainer";
import {HistoryProvider} from "./providers/HistoryProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // Disables ripple globally
            },
        },
    },
});

const mainPages: IPage[] = [
    {
        title: "home",
        path: "/",
        element: <Home />,
    },
    {
        title: "legal-acts",
        path: "/legal-acts",
        element: <LegalActs />,
    },
    {
        title: "projects",
        path: "/projects",
        element: <Projects />,
    },
    {
        title: "chat-ai",
        path: "/chat-ai",
        element: <ChatAIContainer />,
    },
    {
        title: "contact-us",
        path: "/contact-us",
        element: <ContactUs />,
    },
];

const innerPages: IPage[] = [
    {
        title: "views",
        path: "/views",
        element: <Views />,
    },
];

root.render(
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <HistoryProvider>
                  <Layout
                      header={<Header pageTitles={mainPages}/>}
                      content={<Content pages={[...mainPages, ...innerPages]}/>}
                      footer={<div style={{padding: "10px 0 0 20px", color: "white"}}>Copyright © 2024 InfoVers®. All rights reserved.</div>}
                  />
              </HistoryProvider>
          </ThemeProvider>
      </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

