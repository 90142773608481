import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        maxWidth: 700,
        border: "1px solid #9B9ADF",
        borderRadius: 10,
        overflow: "hidden",
    },
}, {
    name: 'news-card',
});

export default useStyles;
