export const colors = {
   serachBar: {
       button: {
           border: "#706EE3",
           default: {
               backgroundColor: '#F4F3F3',
               color: "#706EE3",
           },
           hover: {
               backgroundColor: '#A5A4DD',
               color: "#FFFFFF",
           },
           active: {
               backgroundColor: '#706EE3',
               color: "#FFFFFF",
            },
       }

   },
    groupCard: {
      default: {
          color: "#969696",
          backgroundColor: "#e2e1e11c",
      },
        active: {
            color: "black",
            backgroundColor: "#E2E1E1",
        },
        hover: {
            color: "black",
            backgroundColor: "#E2E1E1",
        },
    },
    chatAiButton: {
        default: {
            color: "black",
            backgroundColor: "white",
        },
        hover: {
            backgroundColor: "#b7cee6",
        },
    },
};
