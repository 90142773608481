import useStyles from "./styles";
import {Typography} from "@mui/material";
import React from "react";

const Projects = () => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <div className={styles.searchContainer}>
                <Typography variant="h4"
                            align='left'
                >
                    Will be available soon...
                </Typography>
            </div>
        </div>
    )
}

export default Projects;
