import useStyles from "./styles";
import {IHeader} from "./types";
import logo from '../../assets/logo.png';
import {Button, MenuItem} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import IconSignin from '../../assets/icons/iconSignIn.svg';
import {IPage} from "../content/types";

const traslatoion = (original: string) => {
    switch (original) {
        case 'home':
            return 'Գլխավոր';
        case 'legal-acts':
            return 'Իրավական ակտեր';
        case 'projects':
            return 'Նախագծեր';
        case 'chat-ai':
            return 'ԱԲ օգնական';
        case 'contact-us':
            return 'Հետադարձ կապ';
        default:
            break;
    }
};
const Header = ({
    pageTitles,
 }: IHeader) => {
    const styles = useStyles()
    const navigate = useNavigate();
    const location = useLocation();
    const isSelected = (path: string) => {
        return location.pathname === path;
    };

    const onButtonClick = () => {
        navigate('/')
    };

    return (
        <div className={styles.root}>
            <div className={styles.imageContainer}>
                <Button onClick={onButtonClick} disableRipple>
                    <img className={styles.logo} src={logo} alt="Logo"/>
                </Button>
            </div>
            <div className={styles.navBarContainer}>
                {pageTitles.map((page: IPage, index: number) => (
                    <MenuItem key={page.title}
                              style={{
                                  borderLeft: "1px solid #C6C5F4",
                                  borderRight: (index === pageTitles.length - 1) ? "1px solid #C6C5F4" : "none",
                                  padding: 0,
                              }}
                              selected={isSelected(page.path)}
                    >
                        <Link
                            className={styles.link}
                            to={`${page.path}`}
                        >
                            {traslatoion(page.title)}
                        </Link>
                    </MenuItem>
                ))}
            </div>
            <Button
                variant={"contained"}
                sx={{
                    borderRadius: '10px',
                    width: 106, minHeight: 56,
                    backgroundColor: '#9998DD',
                    "&:hover": {
                        backgroundColor: '#706EE3'
                    },
                    fontSize: 14,
                    fontWeight: 600,
                }}
                startIcon={<img src={IconSignin} alt="Send Icon" style={{ width: 24, height: 24 }} />}
                >
                Մուտք
            </Button>
        </div>
    )
};

export default Header;
