import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "grid",
        gridTemplateRows: 'auto auto 1fr',
        gridTemplateColumns: '1fr',
        gap: 16,
        height: "100%",
        padding: "0 74px",
    },
    searchContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '120px',
    },
    controlContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 48,
    },
    backIcon: {
        stroke: "#5B5B5B",
        height: 38,
    },
    inactiveBackIcon: {
        stroke: "#cccaca",
        height: 38,
    },
    targetIcon: {
        fill: "#5B5B5B",
        height: 38,
    },
    mainMarkDownContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: 2,
    },

    markDownContainer: {
        flex: 1,
        padding: "10px 8px",
        backgroundColor: "#D9D9D9",
        borderRadius: 10,
    },
    markDown: {
        padding: 16,
        backgroundColor: "#FFFFFF",
    },
}, {
    name: 'home',
});

export default useStyles;
