import React from 'react';
import {IIcon} from "./type";

const IconBack = ({ className}: IIcon) => {
    return (
        <svg
            className={className}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.3246 13.1387L15.8672 18.5961L21.3246 24.0536"
                className="svg-path"
                strokeWidth="2.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="19"
                cy="19"
                r="18.5"
                className="svg-circle"
            />
        </svg>
    );
};

export default IconBack;
