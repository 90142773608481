import {
    MainContainer,
    MessageContainer,
    MessageHeader,
    MessageInput,
    MessageList,
    MinChatUiProvider
} from "@minchat/react-chat-ui";
import {useState} from "react";
import MessageType from "@minchat/react-chat-ui/dist/types/MessageType";
import {sendChatText} from "../../../Services/HttpRequest";
import {ChatCharacter} from "./types";
import {Button} from "@mui/material";
import useStyles from "./styles";
import {colors} from "../../../assets/colors";


// const myColorSet = {
//     // input
//     "--input-background-color": "#FF0000",
//     "--input-text-color": "#fff",
//     "--input-element-color": "rgb(0, 0, 255)",
//     "--input-attach-color": "#fff",
//     "--input-send-color": "#fff",
//     "--input-placeholder-color": "rgb(255, 255, 255)",
//
//     // message header
//     "--message-header-background-color": "#FF0000",
//     "--message-header-text-color": "#fff",
//     "--message-header-last-active-color": "rgb(0, 0, 255)",
//     "--message-header-back-color": "rgb(255, 255, 255)",
//
//     // chat list header
//     "--chatlist-header-background-color": "#FF0000",
//     "--chatlist-header-text-color": "rgb(255, 255, 255)",
//     "--chatlist-header-divider-color": "rgb(0, 128, 0)",
//
//     //chatlist
//     "--chatlist-background-color": "rgb(255, 192, 203)",
//     "--no-conversation-text-color": "rgb(255, 255, 255)",
//
//     //chat item
//     "--chatitem-background-color": "rgb(0, 0, 255)",
//     "--chatitem-selected-background-color": "rgb(255, 255, 0)",
//     "--chatitem-title-text-color": "#FF0000",
//     "--chatitem-content-text-color": "#FF0000",
//     "--chatitem-hover-color": "#FF0000",
//
//     //main container
//     "--container-background-color": "rgb(255, 192, 203)",
//
//     //loader
//     "--loader-color": "rgb(0, 128, 0)",
//
//     //message list
//     "--messagelist-background-color": "rgb(0, 0, 255)",
//     "--no-message-text-color": "rgb(255, 255, 255)",
//
//     // incoming message
//     "--incoming-message-text-color": "rgb(255, 255, 255)",
//     "--incoming-message-name-text-color": "rgb(255, 255, 255)",
//     "--incoming-message-background-color": "rgb(0, 128, 0)",
//     "--incoming-message-timestamp-color": "rgb(255, 255, 255)",
//     "--incoming-message-link-color": "#FF0000",
//
//     //outgoing message
//     "--outgoing-message-text-color": "#FF0000",
//     "--outgoing-message-background-color": "rgb(255, 255, 0)",
//     "--outgoing-message-timestamp-color": "#FF0000",
//     "--outgoing-message-checkmark-color": "#FF0000",
//     "--outgoing-message-loader-color": "#FF0000",
//     "--outgoing-message-link-color": "rgb(0, 128, 0)",
// }

const ChatAI = () => {
    const styles = useStyles();
    const [showTypingIndicator, setShowTypingIndicator] = useState<boolean>(false);
    const [messages, setMessages] = useState<MessageType[]>([{
        text: 'Ինչով կարող եմ օգնել',
        user: {
            id: 'ai',
            name: 'ԱԲ Օգնական',
        },
    }])
    const [hideQuestions, setHideQusetions] = useState<boolean>(false);

    const createMessage = (text: string, character: ChatCharacter) => {
        const name = character === ChatCharacter.ai ? 'ԱԲ Օգնական': 'Օգտատեր';
        return {
            text: text,
            user: {
                id: character,
                name,
            }
        }
    };

    const onSendMessage = (text: string) => {
        setMessages(prevState => [...prevState, createMessage(text, ChatCharacter.human)]);
        setShowTypingIndicator(true);
        const getAnsware = async () => {
            try {
                const fetchedText = await sendChatText(text);
                setShowTypingIndicator(false);
                setMessages(prevState => [...prevState, createMessage(fetchedText.text, ChatCharacter.ai)]);
            } catch (error) {
                console.log("error", error);
            }
        };
        getAnsware();
    };

    const qusetionButtonStyle = {
        width: 190,
        height: 135,
        backgroundColor: colors.chatAiButton.default.backgroundColor,
        color: colors.chatAiButton.default.color,
        borderRadius: 2,
        textTransform: 'none',
        boxShadow: `0 2px 6px ${colors.chatAiButton.hover.backgroundColor}`,
        '&:hover': {
            backgroundColor: colors.chatAiButton.hover.backgroundColor,
        },
    }

    const onQusetionButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        const text = e.currentTarget.textContent;
        if (text) {
            onSendMessage(text);
            setHideQusetions(true);
        }
    }
    return (
        <>
            <MinChatUiProvider theme="#6ea9d7">
                <MainContainer style={{height: '100%', width: '60%', userSelect: 'text',}}>
                    <MessageContainer>
                        <MessageHeader showBack={false}>
                            Բարի գալուստ
                        </MessageHeader>
                        <MessageList
                            currentUserId='human'
                            showTypingIndicator={showTypingIndicator}
                            messages={messages}
                        />
                        <MessageInput placeholder="Type message here" showSendButton onSendMessage={onSendMessage}/>
                    </MessageContainer>
                </MainContainer>
            </MinChatUiProvider>
            {
                !hideQuestions && <div className={styles.questionContainer}>
                    <Button variant={"contained"} sx={qusetionButtonStyle} onClick={onQusetionButton}>
                        Ինչո՞վ կարող ես ինձ օգնել
                    </Button>
                    <Button variant={"contained"} sx={qusetionButtonStyle} onClick={onQusetionButton}>
                        Նկարագրիր ՀՀ Վճռաբեկ դատարանի թիվ ՎԴ/0197/05/19 որոշումը
                    </Button>
                    <Button variant={"contained"} sx={qusetionButtonStyle} onClick={onQusetionButton}>
                        Ի՞նչ է քաղաքացիական իրավական նորմերի կիրառումն անալոգիայով
                    </Button>
                </div>
            }
        </>
    )
}

export default ChatAI;
