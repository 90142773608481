import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const Graph = ({
                   data,
                   onNodeClick,
                   width = window.innerWidth,
                   height = 600,
               }) => {
    const svgRef = useRef();

    useEffect(() => {
        const svg = d3.select(svgRef.current)
            .attr("viewBox", [-width / 2, -height / 2, width, height])
            .attr("width", width)
            .attr("height", height)
            .attr("style", "max-width: 100%; height: auto;");
        svg.selectAll("*").remove();

        const validateLinks = (nodes, links) => {
            const nodeIds = new Set(nodes.map(d => d.id));
            return links.filter(d => nodeIds.has(d.source) && nodeIds.has(d.target));
        };

        const validLinks = validateLinks(data.nodes, data.edges);

        const simulation = d3.forceSimulation(data.nodes)
            .force("link", d3.forceLink(validLinks)
                .id(d => d.id)
                .distance(0.5)
                .strength(d => d.target === 'civil_code' || d.target === 'trial_court_decision' ? 100 : 0.2))
            .force("charge", d3.forceManyBody().strength(-400))
            .force("center", d3.forceCenter(0, 0))
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .alphaDecay(0.1)
            .velocityDecay(0.2);

        const link = svg.append("g")
            .selectAll("line")
            .data(data.edges)
            .enter().append("line")
            .attr("stroke-width", 0.2)
            .attr("stroke", "#c261e8");

        const node = svg.append("g")
            .selectAll("circle")
            .data(data.nodes)
            .enter().append("circle")
            .attr("r", d => {
                switch (d.id) {
                    case 'civil_code':
                    case 'trial_court_decision':
                        return 40;
                    default:
                        return 20;
                }
            })
            .attr("fill", d => {
                switch (d.id) {
                    case 'civil_code':
                        return "#242375";
                    case 'trial_court_decision':
                        return "#706EE3";
                    default:
                        return d.context === 'civil_code' ? "#583fae" : "#706EE3";
                }
            })
            .on("click", (event, d) => onNodeClick(d.id))
            .on("mouseover", (event, d) => {
                const selectedText = text.filter(t => t.id === d.id);
                selectedText.style("visibility", "visible").style("font-size", 60);
                const bbox = selectedText.node().getBBox();
                let rectId = `text-bg-${d.id}`.replace(/\./g, "_").replace(/\//g, "_");
                let rect = d3.select(`#${rectId}`);
                let groupId = `group-${d.id}`.replace(/\./g, "_").replace(/\//g, "_");

                if (rect.empty()) {
                    const group = svg.append("g").attr("id", groupId);
                    rect = group.append("rect")
                        .attr("id", rectId)
                        .attr("fill", "lightgrey")
                        .lower();
                    group.node().appendChild(selectedText.node());
                }

                rect.attr("x", bbox.x - 2)
                    .attr("y", bbox.y - 2)
                    .attr("width", bbox.width + 4)
                    .attr("height", bbox.height + 4)
                    .attr("visibility", "visible");

                d3.select(`#${groupId}`).raise();
            })
            .on("mouseout", (event, d) => {
                const selectedText = text.filter(t => t.id === d.id);
                selectedText.style("visibility", "hidden");
                let rectId = `text-bg-${d.id}`.replace(/\./g, "_").replace(/\//g, "_");
                d3.select(`#${rectId}`).attr("visibility", "hidden");
            });

        const text = svg.append("g")
            .selectAll("text")
            .data(data.nodes)
            .enter().append("text")
            .text(d => d.title)
            .attr("dx", 40)
            .attr("dy", 10)
            .style("visibility", "hidden");

        simulation.on("tick", () => {
            link.attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node.attr("cx", d => d.x).attr("cy", d => d.y);
            text.attr("x", d => d.x).attr("y", d => d.y);
        });

        simulation.on("end", () => {
            console.log("Simulation ended");
            const bounds = svg.node().getBBox();
            const newViewBox = `${bounds.x} ${bounds.y} ${bounds.width} ${bounds.height}`;
            svg.transition()
                .duration(1500)
                .attrTween("viewBox", () => {
                    const interpolate = d3.interpolateString(svg.attr("viewBox"), newViewBox);
                    return t => interpolate(t);
                });
            svg.attr("preserveAspectRatio", "xMidYMid meet");
        });

        // Run the simulation to stabilize it initially
        for (let i = 0; i < 300; i++) {
            simulation.tick();
        }
    }, [data]);

    return (
        <svg ref={svgRef} width={width} height={height} style={{zIndex: 0}}/>
    );
};

export default Graph;
