import React from "react";
import {IIcon} from "./type";

const IconClose = ({
    className,
 }: IIcon) => {
   return(
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={className}
        fill="currentColor" // This makes it use the CSS `color` property
    >
        <path
            d="M11.2932 8.29279C11.4807 8.10532 11.735 8 12.0002 8C12.2653 8 12.5197 8.10532 12.7072 8.29279L18.3642 13.9498C18.5463 14.1384 18.6471 14.391 18.6449 14.6532C18.6426 14.9154 18.5374 15.1662 18.352 15.3516C18.1666 15.537 17.9158 15.6422 17.6536 15.6445C17.3914 15.6467 17.1388 15.5459 16.9502 15.3638L12.0002 10.4138L7.05018 15.3638C6.86158 15.5459 6.60898 15.6467 6.34678 15.6445C6.08458 15.6422 5.83377 15.537 5.64836 15.3516C5.46295 15.1662 5.35778 14.9154 5.35551 14.6532C5.35323 14.391 5.45402 14.1384 5.63618 13.9498L11.2932 8.29279Z"/>
    </svg>
   )};

export default IconClose;
